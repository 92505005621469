import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SingleBlogItem from "../../../components/single-blog-item";
import { Container, Row, Col } from "react-bootstrap";
import { RecentArticleArea, SectionTitleArea, Title } from "./style";
const RecentArticlesArea = () => {
    const recentArticlesAreaQuery = useStaticQuery(graphql`
        query RecentArticlesQuery {
            allStrapiArticle(
                sort: { fields: [updated_at], order: DESC }
                limit: 6
            ) {
                edges {
                    node {
                        author {
                            name
                            slug
                        }
                        id
                        title
                        slug
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 440
                                        height: 304
                                        quality: 100
                                    )
                                }
                            }
                            updated_at
                        }
                    }
                }
            }
        }
    `);

    const recentArticleData = recentArticlesAreaQuery.allStrapiArticle.edges;

    return (
        <RecentArticleArea>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitleArea>
                            <Title>Recent Article</Title>
                        </SectionTitleArea>
                    </Col>
                </Row>
                <Row className="gx-5">
                    {recentArticleData &&
                        recentArticleData.map((latestPosts, i) => {
                            return (
                                <Col lg={4} md={6} key={i}>
                                    <SingleBlogItem
                                        title={latestPosts.node.title}
                                        thume_image={
                                            latestPosts.node.image.localFile
                                        }
                                        date={latestPosts.node.image.updated_at}
                                        slug={latestPosts.node.slug}
                                        body={latestPosts.node.description}
                                        authorId={latestPosts.node.author.slug}
                                        postAuthor={
                                            latestPosts.node.author.name
                                        }
                                        dateSlug={
                                            latestPosts.node.image.updated_at
                                        }
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Container>
        </RecentArticleArea>
    );
};

export default RecentArticlesArea;
