import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    SingleBlogWrap,
    BlogContent,
    Author,
    Title,
    PostMeta,
    PostDate,
    DecText,
    PostReadTime,
} from "./style";

const SingleBlogItem = ({
    title,
    thume_image,
    body,
    date,
    dateSlug,
    slug,
    authorId,
    postAuthor,
}) => {
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    date = formatDate(date);
    dateSlug = formatDate(dateSlug);

    const image = getImage(thume_image);
    return (
        <SingleBlogWrap>
            <Link to={`/${slug}/`}>
                <GatsbyImage image={image} alt={title} />
            </Link>
            <BlogContent>
                <Author>
                    By <Link to={`/profile/${authorId}/`}>{postAuthor}</Link>
                </Author>
                <Title>
                    <Link to={`/${slug}/`}>{title}</Link>
                </Title>
                <DecText>{body}</DecText>
                <PostMeta>
                    <PostDate>{date}</PostDate>
                    <PostReadTime>10 min read</PostReadTime>
                </PostMeta>
            </BlogContent>
        </SingleBlogWrap>
    );
};

export default SingleBlogItem;
