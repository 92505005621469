import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    SingleMostPopulerItem,
    MostPopulerContent,
    Author,
    Title,
    PostMeta,
    PostDate,
    PostReadTime,
} from "./style";
const MostPopulerItem = ({
    title,
    thume_image,
    dateSlug,
    authorId,
    postAuthor,
    date,
    slug,
}) => {
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    date = formatDate(date);
    dateSlug = formatDate(dateSlug);

    const images = getImage(thume_image);
    return (
        <SingleMostPopulerItem>
            <Link to={`/${slug}/`}>
                <GatsbyImage image={images} alt={title} />
            </Link>
            <MostPopulerContent>
                <Author>
                    By <Link to={`/profile/${authorId}/`}>{postAuthor}</Link>
                </Author>
                <Title>
                    <Link to={`/${slug}/`}>{title}</Link>
                </Title>
                <PostMeta>
                    <PostDate>{date}</PostDate>
                    <PostReadTime>10 min read</PostReadTime>
                </PostMeta>
            </MostPopulerContent>
        </SingleMostPopulerItem>
    );
};

export default MostPopulerItem;
