/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { slugify } from "@utils/functions";
import Button from "@components/shared/button";
import {
    HeroAreaWrapper,
    HeroTwoArea,
    HeroAreaTwoInnter,
    HeroTwoTag,
    ShorTitle,
    Title,
} from "./style";

const HeroTwo = () => {
    const heroTwoPostQuery = useStaticQuery(graphql`
        query Query {
            allStrapiCategory {
                edges {
                    node {
                        name
                    }
                }
            }
            strapiHomepage {
                id
                hero {
                    title
                }
                seo {
                    metaDescription
                    metaTitle
                }
            }
        }
    `);

    const {
        seo: { metaTitle, metaDescription },
    } = heroTwoPostQuery.strapiHomepage;
    const { allStrapiCategory } = heroTwoPostQuery;

    return (
        <HeroAreaWrapper>
            <HeroTwoArea>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <HeroAreaTwoInnter>
                                <ShorTitle>{metaDescription}</ShorTitle>
                                <Title>{metaTitle}</Title>
                                <HeroTwoTag>
                                    {allStrapiCategory &&
                                        allStrapiCategory.edges.map(
                                            (catItem, i) => {
                                                return (
                                                    <Button
                                                        sx={{
                                                            border:
                                                                "2px solid #fff",
                                                            lineHeight: "46px",
                                                            padding: [
                                                                "0 15px",
                                                                "0 35px",
                                                            ],
                                                        }}
                                                        key={i}
                                                        shape="rounded-10"
                                                        variant="outlined"
                                                        path={`/category/${slugify(
                                                            catItem.node.name
                                                        )}/`}
                                                    >
                                                        {catItem.node.name}
                                                    </Button>
                                                );
                                            }
                                        )}
                                </HeroTwoTag>
                            </HeroAreaTwoInnter>
                        </Col>
                    </Row>
                </Container>
            </HeroTwoArea>
        </HeroAreaWrapper>
    );
};

export default HeroTwo;
