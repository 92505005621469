import { Link } from "gatsby";
import {
    SpecialBannerBlogPost,
    SingleSpecialBannerPost,
    SpecialBannerPostContent,
    BannerPostAuthor,
    Title,
    DecText,
    BannerPostMeta,
    PostDate,
    ReadTime,
} from "./style";

const SpecialBannerOne = ({
    title,
    body,
    date,
    authorSlug,
    PostAuthor,
    dateSlug,
    slug,
}) => {
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    date = formatDate(date);
    dateSlug = formatDate(dateSlug);

    return (
        <SpecialBannerBlogPost>
            <SingleSpecialBannerPost>
                <SpecialBannerPostContent>
                    <BannerPostAuthor>
                        By{" "}
                        <Link to={`/profile/${PostAuthor}/`}>{authorSlug}</Link>
                    </BannerPostAuthor>
                    <Title>
                        <Link to={`/${slug}/`}>{title}</Link>
                    </Title>
                    <DecText>{body}</DecText>
                    <BannerPostMeta>
                        <PostDate>{date}</PostDate>
                        <ReadTime>10 min read</ReadTime>
                    </BannerPostMeta>
                </SpecialBannerPostContent>
            </SingleSpecialBannerPost>
        </SpecialBannerBlogPost>
    );
};

export default SpecialBannerOne;
