import React from "react";
import Swiper, { SwiperSlide } from "@components/swiper";
import { graphql, useStaticQuery } from "gatsby";
import MostPopulerItem from "@components/most-populer-item";
import { Container, Row, Col } from "react-bootstrap";
import {
    MostPopulerArea,
    SectionTitle,
    MostPopularSliderNavigation,
    NavigationButton,
} from "./style";

const MostPopularArea = () => {
    const tredingArticleTwoQuery = useStaticQuery(graphql`
        query TredingArticleTwoQuery {
            allStrapiArticle(sort: { fields: [updated_at], order: ASC }) {
                edges {
                    node {
                        author {
                            name
                            slug
                        }
                        id
                        title
                        slug
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 372
                                        height: 272
                                        quality: 100
                                    )
                                }
                            }
                            updated_at
                        }
                    }
                }
            }
        }
    `);

    const tredingArticleData = tredingArticleTwoQuery.allStrapiArticle.edges;

    return (
        <MostPopulerArea>
            <Container>
                <Row>
                    <Col xs={8}>
                        <SectionTitle>Most Popular</SectionTitle>
                    </Col>
                    <Col xs={4}>
                        <MostPopularSliderNavigation>
                            <NavigationButton className="most-populer-button-prev">
                                <i className="icofont-long-arrow-left"></i>
                            </NavigationButton>
                            <NavigationButton className="most-populer-button-next">
                                <i className="icofont-long-arrow-right"></i>
                            </NavigationButton>
                        </MostPopularSliderNavigation>
                    </Col>
                </Row>
                <Swiper
                    layout={{
                        nav: "most-populer-navigation",
                        dots: "most-populer-dots-style",
                    }}
                    navigation={{
                        nextEl: ".most-populer-button-next",
                        prevEl: ".most-populer-button-prev",
                    }}
                    centeredSlides={false}
                    slidesPerView={3}
                    spaceBetween={30}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        560: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {tredingArticleData &&
                        tredingArticleData.map((item, index) => {
                            return (
                                <SwiperSlide key={`most-populer-${index}`}>
                                    <MostPopulerItem
                                        title={item.node.title}
                                        thume_image={item.node.image.localFile}
                                        slug={item.node.slug}
                                        authorId={item.node.author.slug}
                                        postAuthor={item.node.author.name}
                                        date={item.node.image.updated_at}
                                        dateSlug={item.node.image.updated_at}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </Container>
        </MostPopulerArea>
    );
};

export default MostPopularArea;
