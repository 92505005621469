import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NewsletterSubscribeTwo from "../../../components/newsletter-subscribe-two";

const NewsLettersArea = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <NewsletterSubscribeTwo />
                </Col>
            </Row>
        </Container>
    );
};

export default NewsLettersArea;
