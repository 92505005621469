import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SpecialBannerOne from "../../../components/special-banner";
import {
    SpecialForBeginnerArea,
    SectionTitleTwo,
    Title,
    SpecialBannerImage,
} from "./style";

const SpecialForBeginner = () => {
    const specialForBeginnerQuery = useStaticQuery(graphql`
        query SpecialForBeginnerQuery {
            allStrapiArticle(
                filter: { author: { name: { eq: "Kiran Naz" } } }
            ) {
                edges {
                    node {
                        author {
                            name
                            slug
                        }
                        id
                        title
                        slug
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 372
                                        height: 272
                                        quality: 100
                                    )
                                }
                            }
                            updated_at
                        }
                    }
                }
            }
        }
    `);

    const specialBeginnerOneData =
        specialForBeginnerQuery.allStrapiArticle.edges;

    return (
        <SpecialForBeginnerArea>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitleTwo>
                            <Title>Special For Beginner</Title>
                        </SectionTitleTwo>
                    </Col>
                </Row>
                <Row>
                    <Col lg={7}>
                        <SpecialBannerImage>
                            <StaticImage
                                src="../../../data/images/banners/special-banner.jpg"
                                alt="Special Banner Image"
                            />
                        </SpecialBannerImage>
                    </Col>
                    <></>
                    <Col lg={5}>
                        {specialBeginnerOneData &&
                            specialBeginnerOneData.map((item, i) => {
                                return (
                                    <SpecialBannerOne
                                        title={item.node.title}
                                        body={item.node.description}
                                        date={item.node.image.updated_at}
                                        dateSlug={item.node.image.updated_at}
                                        authorSlug={item.node.author.name}
                                        PostAuthor={item.node.author.slug}
                                        slug={item.node.slug}
                                    />
                                );
                            })}
                    </Col>
                </Row>
            </Container>
        </SpecialForBeginnerArea>
    );
};

export default SpecialForBeginner;
