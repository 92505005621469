import * as React from "react";
import SEO from "../components/seo.jsx";
import Layout from "../layouts/index.jsx";
import HeroTwo from "../container/home-two/hero";
import MostPopularArea from "../container/home-two/most-popular/index.jsx";
import RecentArticlesArea from "../container/home-two/recent-article/index.jsx";
import NewsLettersArea from "../container/home-two/newsletter/index.jsx";
import SpecialForBeginner from "../container/home-two/special-for-beginner/index.jsx";

const IndexTwoPage = () => {
    return (
        <Layout>
            <SEO />
            <HeroTwo />
            <MostPopularArea />
            <RecentArticlesArea />
            <NewsLettersArea />
            <SpecialForBeginner />
        </Layout>
    );
};

export default IndexTwoPage;
